import { mediaQuery } from '../util/ui';
import { RecipeList } from '../components/recipe/list';
import React, { useMemo, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { getRecipes } from '../store/actions/api/get-recipes';
import { RecipeFilter } from '../types/api/entities/recipe-filter';
import { FilterInfo } from '../store/state/entities';
import { getStore } from '../store/actions/api/get-store';
import { Frame } from '../components/frame/frame';
import { formatTitle } from '../util/format-title';
import Head from 'next/head';
import { Hero } from '../components/recipe/hero';
import { useEffect } from 'react';
import { getFilters } from '../store/actions/api/get-filters';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetServerSideProps } from 'next';
import { useTranslation } from 'next-i18next';
import { useAppSelector } from '../store/use-store';
import { ApiService, AxiosWithApi } from '../services/api';
import { notUndefined } from '../util/not-undefined';
import { PartialRecipe, RecipeOptionWithFilter } from '../types/api/entities/recipe';
import { Paginated } from '../types/api/paginated';
import { decodeFiltersUrlPath } from '../util/url';
import { redirectToRootUrlIndex } from '../util/redirect-to-root-url';

const classNames = {
  navigation: 'recipe-list__navigation',
  recipes: 'recipe-list__items',
};

function getFiltersF(filter: FilterInfo): RecipeFilter[] {
  const { allIds, byId } = filter;
  return allIds.map((id) => byId[id]).filter(notUndefined);
}

type PageProps = {
  recipes: Paginated<PartialRecipe>;
  filters?: RecipeOptionWithFilter[];
};
export default function IndexPage({ recipes: preloadedRecipes, filters }: PageProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { recipe, store, filter } = useAppSelector((s) => s.entities);
  const [isInitial, setIsInitial] = useState(true);
  const [recipes, total] = useAppSelector((s) => {
    const allRecipes = s.entities.recipe.query.current;

    if (isInitial) {
      return [preloadedRecipes.items, preloadedRecipes.total];
    } else {
      return [allRecipes.ids.map((id) => recipe.byId[id]).filter(notUndefined), allRecipes.total];
    }
  }, shallowEqual);

  useEffect(() => {
    dispatch(getStore());

    dispatch(getRecipes({ options: { filters } }));

    dispatch(getFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recipeFilters = useMemo(() => getFiltersF(filter), [filter]);

  return (
    <div>
      <Head>
        <title>{formatTitle(store, t('AllRecipes'))}</title>
      </Head>
      <Frame>
        {recipes[0] && (
          <>
            <div className="featured-recipe">
              <div className="featured-recipe__inner">
                <Hero />
              </div>
            </div>
          </>
        )}
        <RecipeList
          deactivateRatings={
            store.theme && store.theme.deactivateRatings ? store.theme.deactivateRatings : false
          }
          getRecipes={(event) => {
            if (isInitial) {
              setIsInitial(false);
            }
            dispatch(getRecipes(event));
          }}
          recipes={recipes}
          filters={recipeFilters}
          onLoadMoreClick={() => {
            if (isInitial) {
              setIsInitial(false);
            }
            dispatch(
              getRecipes({
                previousIds: recipes.map((r) => r.id),
                loadMore: true,
                options: {
                  offset: recipe.query.current.ids.length,
                },
              }),
            );
          }}
          showMoreEnabled={total > recipes.length}
          initialFilters={filters}
        />
      </Frame>
      <style jsx>{`
        .featured-recipe {
          display: flex;
          justify-content: center;
        }

        .featured-recipe__inner {
          width: 100%;
          max-width: 1360px;
        }

        div :global(.${classNames.navigation}) {
          margin-top: 24px;
        }

        div :global(.${classNames.recipes}) {
          margin-top: 28px;
        }

        @media ${mediaQuery.Medium} {
          div :global(.${classNames.navigation}) {
            margin-top: 43px;
          }

          div :global(.${classNames.recipes}) {
            margin-top: 32px;
          }
        }

        @media ${mediaQuery.ExtraLarge} {
          div :global(.${classNames.navigation}) {
            margin-top: 49px;
          }

          div :global(.${classNames.recipes}) {
            margin-top: 112px;
          }
        }
      `}</style>
    </div>
  );
}

export const getServerSideProps: GetServerSideProps<PageProps> = async ({
  locale,
  req,
  res,
  params,
}: any) => {
  let apiBaseUrl: string;
  locale = locale || '';

  const cookie = req.headers.cookie || '';
  const host = req.headers['codomain'];
  if (host) {
    apiBaseUrl = `https://${host}/api`;
  } else {
    apiBaseUrl = '/api';
  }

  const Api = new ApiService(AxiosWithApi({ apiBaseUrl, cookie }));
  const filters = params?.query ? decodeFiltersUrlPath(params.query) : undefined;
  const recipes = await Api.getRecipes({ filters });
  const store = await Api.getStore();

  if (store.rootUrl) {
    /**
     * If rootUrl is set it means the store has chosen to use another page where they've likely used
     * embedded the Recipe Explorer on a page of theirs which is where rootUrl points to.
     * So, let's redirect to it.
     */

    redirectToRootUrlIndex(res, store.rootUrl, filters ?? []);
  }

  const props: PageProps = {
    ...(await serverSideTranslations(locale, ['common'])),
    // Will be passed to the page component as props
    recipes,
  };

  if (filters) {
    props.filters = filters;
  }

  return {
    props,
  };
};
