import React from 'react';
import { CustomBannerHero as CustomBannerHeroOptions } from '../../types/api/entities/store';
import { mediaQuery, CONTENT_MAX_WIDTH, minimumSize } from '../../util/ui';

export interface Props {
  options: CustomBannerHeroOptions;
}

export class CustomBannerHero extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { text, imageUrls } = this.props.options;
    return (
      <div className="container">
        <img
          alt={text}
          src={imageUrls.original}
          srcSet={`
            ${imageUrls.S} 768w,
            ${imageUrls.M} 1024w,
            ${imageUrls.L} 1440w
          `}
          sizes={`
            (min-width: ${minimumSize.Medium}) 100vw,
            (min-width: ${minimumSize.ExtraLarge}) 1360w,
            100vw
          `}
        />

        <div className="text-container">
          <div className="text-container__inner">
            <h1>{text}</h1>
          </div>
        </div>
        <style jsx>{`
          .container {
            position: relative;
          }

          img {
            width: 100%;
          }

          .text-container,
          .text-container__inner {
            display: flex;
            justify-content: center;
            width: 100%;
          }

          .text-container {
            position: absolute;
            bottom: 24px;
          }

          .text-container__inner {
            max-width: ${CONTENT_MAX_WIDTH};
            padding: 0 24px;
          }

          h1 {
            cursor: default;
            font-size: 40px;
            font-family: var(--font-family-secondary);
            font-weight: normal;
            color: #fff;
            margin: 0;
          }

          @media ${mediaQuery.Medium} {
            .text-container__inner {
              justify-content: left;
            }

            h1 {
              font-size: 60px;
            }
          }
        `}</style>
      </div>
    );
  }
}
