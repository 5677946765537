// tslint:disable:max-line-length

import React from 'react';

export interface RatingProps {
  value: number;
}

export class Rating extends React.PureComponent<RatingProps> {
  constructor(props: RatingProps) {
    super(props);
    if (props.value < 1 || props.value > 5) {
      throw new Error(`Expected value to be between 1 and 5, but received ${props.value}`);
    }
  }

  public render() {
    return (
      <div className="container">
        <svg width="122px" height="20px" viewBox="0 0 122 20">
          <g>
            <polygon
              fill={this.getColor(1)}
              points="10.5 15.75 4.32825485 18.9946784 5.50695329 12.1223392 0.513906579 7.25532156 7.41412743 6.25266078 10.5 0 13.5858726 6.25266078 20.4860934 7.25532156 15.4930467 12.1223392 16.6717451 18.9946784"
            />
            <polygon
              fill={this.getColor(2)}
              points="35.5 15.75 29.3282549 18.9946784 30.5069533 12.1223392 25.5139066 7.25532156 32.4141274 6.25266078 35.5 0 38.5858726 6.25266078 45.4860934 7.25532156 40.4930467 12.1223392 41.6717451 18.9946784"
            />
            <polygon
              fill={this.getColor(3)}
              points="61.5 15.75 55.3282549 18.9946784 56.5069533 12.1223392 51.5139066 7.25532156 58.4141274 6.25266078 61.5 0 64.5858726 6.25266078 71.4860934 7.25532156 66.4930467 12.1223392 67.6717451 18.9946784"
            />
            <polygon
              fill={this.getColor(4)}
              points="86.5 15.75 80.3282549 18.9946784 81.5069533 12.1223392 76.5139066 7.25532156 83.4141274 6.25266078 86.5 0 89.5858726 6.25266078 96.4860934 7.25532156 91.4930467 12.1223392 92.6717451 18.9946784"
            />
            <polygon
              fill={this.getColor(5)}
              points="111.5 15.75 105.328255 18.9946784 106.506953 12.1223392 101.513907 7.25532156 108.414127 6.25266078 111.5 0 114.585873 6.25266078 121.486093 7.25532156 116.493047 12.1223392 117.671745 18.9946784"
            />
          </g>
        </svg>
        <span className="text-value">{this.props.value}</span>
        <style jsx>{`
          .container {
            display: inline-flex;
            align-items: center;
          }

          .text-value {
            margin-left: 7.5px;
            font-size: 15px;
            font-family: var(--font-family-primary);
          }
        `}</style>
      </div>
    );
  }

  private getColor(min: number) {
    return this.props.value >= min ? '#ffc900' : '#d8d8d8';
  }
}
