import { FilterTag } from './filter-tag';
import { RecipeFilterOption } from '../../types/api/entities/recipe-filter';

export interface FilterTagsProps {
  items?: RecipeFilterOption[];
}

export const FilterTags = (props: FilterTagsProps) => {
  return props.items ? (
    <>
      {props.items.map((item) => (
        <div key={item.id}>
          <FilterTag filter={item} />
        </div>
      ))}
      <style jsx>{`
        div {
          display: inline-block;
        }

        div:not(:first-child) {
          margin-left: 10px;
        }
      `}</style>
    </>
  ) : null;
};
