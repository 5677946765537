import React from 'react';
import { connect } from 'react-redux';
import { StoreHeroSettings } from '../../types/api/entities/store';
import { StoreState } from '../../store/state';
import { FeaturedRecipe } from './featured';
import { CustomBannerHero } from './custom-banner-hero';

interface StateProps {
  settings?: StoreHeroSettings;
}

export type Props = StateProps;

// tslint:disable-next-line:class-name
class _Hero extends React.Component<Props> {
  public render() {
    const { settings } = this.props;
    if (!settings || settings.type === 'latest-recipe') {
      return <FeaturedRecipe />;
    } else {
      return <CustomBannerHero options={settings} />;
    }
  }
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    settings: state.entities.store.theme && state.entities.store.theme.hero,
  };
}

export const Hero = connect(mapStateToProps)(_Hero);
