import { RecipeImage, ImageAspectRatio, RecipeMedia } from '../types/api/entities/recipe';

export const getMainImage = (media: RecipeMedia[]) =>
  media.find((item) => item.type === 'image') as RecipeImage | undefined;

export const getImageUrls = (image: RecipeImage, defaultSize: ImageAspectRatio) => {
  return {
    src: image.urls[defaultSize].L,
    srcSet: `
    ${image.urls['1x1'].L} 400w,
    ${image.urls['4x3'].L} 800w,
    ${image.urls['16x9'].L} 1024w
    `,
  };
};

export const getMainImageUrls = (media: RecipeMedia[], defaultSize: ImageAspectRatio) => {
  if (media) {
    const mainImage = getMainImage(media);

    if (mainImage) {
      return getImageUrls(mainImage, defaultSize);
    }
  }

  return undefined;
};
