import React from 'react';
import { RecipeFilterOption } from '../../types/api/entities/recipe-filter';

export interface FilterTagProps {
  filter: RecipeFilterOption;
}

export class FilterTag extends React.PureComponent<FilterTagProps> {
  public render() {
    return (
      <>
        <div className="filter-tag">
          <span className="filter-tag__text">{this.props.filter.name}</span>
        </div>
        <style jsx>{`
          .filter-tag {
            display: inline-flex;
            align-items: center;
            height: 32px;
            background-color: #f2f2f2;
            box-sizing: border-box;
            padding: 7px 10px;
            border-radius: 4px;
          }

          .filter-tag__text {
            color: #343434;
            font-family: var(--font-family-primary);
            font-weight: normal;
            font-size: 15px;
          }
        `}</style>
      </>
    );
  }
}
