import React from 'react';
import { mediaQuery, CONTENT_MAX_WIDTH } from '../../util/ui';
import { PartialRecipe } from '../../types/api/entities/recipe';
import { Rating } from './rating';
import { FilterTags } from './filter-tags';
import { RecipeFilterOption } from '../../types/api/entities/recipe-filter';
import { StoreState } from '../../store/state';
import { connect } from 'react-redux';
import { LinkCreator } from '../../util/linker';
import { getMainImageUrls } from '../../util/image';
import Link from 'next/link';
import { notUndefined } from '../../util/not-undefined';

export interface StateProps {
  recipe?: PartialRecipe;
  deactivateRatings: boolean;
  filters?: (RecipeFilterOption | undefined)[];
}

export type FeaturedRecipeProps = StateProps;

class FeaturedRecipeComponent extends React.PureComponent<FeaturedRecipeProps> {
  public render() {
    if (!this.props.recipe) return null;
    const { recipe, filters, deactivateRatings } = this.props;

    const urls = getMainImageUrls(recipe.media, '16x9');

    return (
      <Link href={LinkCreator.toRecipe(this.props.recipe)} scroll={true}>
        <a>
          <div className="featured-recipe">
            {urls && (
              <img
                alt="Featured recipe image"
                className="featured-recipe__image"
                src={urls.src}
                srcSet={urls.srcSet}
                sizes="100vw"
              />
            )}
            <div className="content-wrapper">
              <div className="featured-recipe__content">
                {deactivateRatings ? null : <Rating value={Number(recipe.rating)} />}
                <h2 className="featured-recipe__title">{recipe.name}</h2>
                <div className="featured-recipe__tags">
                  <FilterTags items={filters?.filter(notUndefined)} />
                </div>
              </div>
            </div>
            <hr />
          </div>
          <style jsx>{`
            a,
            a:visited {
              color: inherit;
              text-decoration: none;
            }

            .featured-recipe__image {
              object-fit: cover;
              object-position: center center;
              width: 100%;
              height: 282px;
              border-radius: 4px;
            }

            .featured-recipe__content {
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 20px;
              padding-bottom: 20px;
              text-align: center;
            }

            hr {
              border-top: 1px solid #d8d8d8;
              border-bottom: none;
              margin: 0;
            }

            .featured-recipe__title {
              font-size: 30px;
              margin-top: 10px;
              margin-bottom: 0;
              font-family: var(--font-family-secondary);
              font-weight: normal;
              color: #131415;
            }

            .featured-recipe__tags {
              margin-top: 17px;
            }

            @media ${mediaQuery.Medium} {
              .featured-recipe__image {
                height: 432px;
              }

              .featured-recipe__content {
                padding-top: 30px;
                padding-bottom: 24px;
              }

              .featured-recipe__tags {
                margin-top: 24px;
              }
            }

            @media ${mediaQuery.ExtraLarge} {
              hr {
                display: none;
              }

              .featured-recipe {
                position: relative;
              }

              .featured-recipe__image {
                height: 628px;
              }

              .content-wrapper {
                position: absolute;
                bottom: 67px;
                display: flex;
                justify-content: center;
                width: 100%;
              }

              .featured-recipe__content {
                background-color: #fff;
                border-radius: 4px;
                box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.2);
                padding-top: 27px;
                padding-bottom: 19px;
                padding-left: 37px;
                padding-right: 37px;
                max-width: ${CONTENT_MAX_WIDTH};
              }

              .featured-recipe__title {
                font-size: 60px;
              }
            }
          `}</style>
        </a>
      </Link>
    );
  }
}

function mapStateToProps(state: StoreState): StateProps {
  const { recipe: recipes, store, option } = state.entities;
  const { byId } = option;

  const topRecipeId = recipes.query.current.ids[0];
  let recipe;
  if (topRecipeId) {
    recipe = recipes.byId?.[topRecipeId];
  }
  return {
    filters: recipe?.filters.flatMap((id) => byId[id]),
    recipe,
    deactivateRatings:
      store.theme && store.theme.deactivateRatings ? store.theme.deactivateRatings : false,
  };
}

export const FeaturedRecipe = connect(mapStateToProps)(FeaturedRecipeComponent);
